<template>
  <b-modal
    size="sm"
    id="modal-toast-confirm"
    :title="title"
    v-model="open"
    modal-class="d-flex align-items-center align-content-center modal-toast-confirm "
    dialog-class="mx-auto"
    :header-class="`py-2 bg-transparent ${!title ? 'd-none':''}`"
    title-class="text-center w-100 mt-1 text-gray-dark-3 font-weight-normal"
    body-class="text-center body-toast"
    footer-class="footer-button border-top-0 "
    no-close-on-esc
    no-close-on-backdrop
    no-close
    hide-header-close
  >
    <div class="text-center">
      <p class="mb-0 body-message">
        {{firstContent}}<b v-if="boldContent">{{' ' + boldContent}}</b>{{lastContent}}
      </p>
    </div>
    <template #modal-footer>
      <b-btn variant="light" class="bg-transparent mr-10 font-size-14 border rounded font-weight-normal text-gray-dark-2 text-uppercase" @click="onCancel">
        {{ buttonText[0] }}
      </b-btn>
      <b-btn
        :variant="danger ? 'danger' : 'success'"
        :disabled="isLoading"
        class="font-size-14 ml-10 font-weight-600 rounded border-0 text-white text-uppercase"
        @click="onOK"
      >
        <b-spinner v-if="isLoading" small variant="light" type="grow" label="Loading..."></b-spinner>
        <span> {{ buttonText[1] }}</span>
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
export default {
  data : ()=> ({
    open         : false,
    Ok           : false,
    title        : '',
    firstContent : '',
    lastContent  : '',
    boldContent  : '',
    dataEmit     : {},
    isLoading    : false,
    danger       : false,
    buttonText   : ['Cancel', 'Confirm'],
  }),

  methods: {
    openConfirmModal(title = '', firstContent = '', boldContent = '',lastContent = '', dataEmit = {}, danger, buttonText) {
      this.open         = true
      this.title        = title
      this.firstContent = firstContent
      this.lastContent  = lastContent
      this.boldContent  = boldContent
      this.dataEmit     = dataEmit
      this.danger       = danger

      if(buttonText) {
        this.buttonText = buttonText
      }
    },
    closeToast() {
      this.open      = false
      this.isLoading = false
    },
    onCancel() {
      this.$emit('onCancelToast', this.dataEmit)
      this.open = false
    },
    onOK() {
      this.isLoading = true
      this.$emit('onOkToast', this.dataEmit)
    },
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/pages/_modal.scss';
</style>
