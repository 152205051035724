import moment from 'moment-timezone'
import {PDFDocument} from 'pdf-lib'
import errorIconURL   from '../assets/images/font-awsome-svg/error-icon.svg'
import successIconURL from '../assets/images/font-awsome-svg/success-icon.svg'
import infoIconURL    from '../assets/images/font-awsome-svg/info-icon.svg'
import warningIconURL from '../assets/images/font-awsome-svg/warning-icon.svg'
let store = undefined
export default{
  /**
  * @Description
  *
  * 1. This computed prop is used to check if it's a mobile view or desktop
  * 2. Checks the windows width is less than 600, if it's mobile then it returns the true
  *
  * @return Boolean { true | false }
  **/
  computed:{
    isMobile(){
      return window.innerWidth < 767
    },
    getMobileOS() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera
    
      // Windows Phone must come first because its UA also contains 'Android'
      if (/windows phone/i.test(userAgent)) {
          return 'windows';
      }
    
      if (/android/i.test(userAgent)) {
          return 'android';
      }
    
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return 'ios';
      }
    
      return 'unknown';
    },
    isTablet(){
      return window.innerWidth < 981
    },
    user(){
      return this.$store.getters.EMPLOYEE_DATA
    },
    workOrderStatus(){
      return [
        {
          id         : 0,
          name       : this.$t('0066'),
          color      : 'text-gray-dark-3',
          outline    : 'outline-dark text-gray-dark-3 bg-gray-dark-6',
          background : 'bg-light-2',
          icon       : 'far fa-circle-dot',
        },
        {
          id         : 1,
          name       : this.$t('0053'),
          color      : 'text-orange',
          outline    : 'outline-warning text-orange bg-orange-light-2',
          background : 'bg-orange-light-2',
          icon       : 'fa-solid fa-arrow-right-long',
        },
        {
          id         : 2,
          name       : this.$t('0067'),
          color      : 'text-success',
          outline    : 'outline-success text-success bg-green-light-2',
          background : 'bg-green-light-2',
          icon       : 'fas fa-check',
        },
        {
          id         : 3,
          name       : this.$t('0068'),
          color      : 'text-gray-dark-3',
          outline    : 'outline-dark text-gray-dark-3 bg-gray-dark-6',
          background : 'bg-light-2',
          icon       : 'fas fa-xmark',
        }
      ];
    }
  },
  mounted(){    
    store = this.$store
  },
  methods:{
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)){
        return !this.veeErrors.has(ref)
      }
      return null;
    },
    makeObjectEmpty(obj){
      Object.keys(obj).forEach((i) => obj[i] = null)
    },
    /*******TRN********/
    unixTimeFormatDefault(unixStr,hasSecond = false) {
      let type = ''
      if(hasSecond == false){
        type = this.$store.getters.TIME_FORMAT_DEFAULT ? 'HH:mm' : 'hh:mmA';
      }else{
        type = this.$store.getters.TIME_FORMAT_DEFAULT ? 'HH:mm:ss' : 'hh:mm:ssA';
      }
      return moment.unix(unixStr).tz(this.$store.getters.TIMEZONE).format(type);
    },
    formatUnixTimet(unixStr, type = 'MM/DD/YYYY', add1Minute=false) {
      let momentDate = add1Minute == true ? moment.unix(unixStr).add(1,'m') : moment.unix(unixStr)
      return momentDate.tz(this.$store.getters.TIMEZONE).format(type)
    },
    defaultFormatUnix(unixStr) {
      return moment.unix(unixStr).tz(this.$store.getters.TIMEZONE).format('hh:mm:ss A');
    },
    formatTotalSecond(seconds, type = 'HH:mm:ss') {
      return moment.utc(seconds * 1000).format(type);
    },
    getCurrentDate(format = 'YYYY-MM-DD') {
      let lang = store.getters?.EMPLOYEE_DATA?.language?.code?.toLowerCase() ?? 'en'
      return moment().lang(lang).tz(this.$store.getters.TIMEZONE).format(format);
    },
    addTime(date, numberAdd, typeAdd = 'seconds', format = 'HH:mm:ss') {
      return moment(date).add(numberAdd, typeAdd).format(format);
    },
    formatDate(date, type = 'DD/MM/YYYY') {
      date = moment(date).format(type);
      return date;
    },
    formatTimeToTotalTime(time) {
      var timeMoment = moment(time, 'HH:mm:ss');
      var totalSeconds = timeMoment.hours() * 3600 + timeMoment.minutes() * 60 + timeMoment.seconds();
      return totalSeconds
    },
    formatTotalTime(second_total, typeReturn = 'full') {
      let hour = Math.floor(second_total / 3600);
      let minute = Math.floor((second_total % 3600) / 60);
      let second = second_total % 60;
      hour = hour < 10 ? '0' + hour : hour;
      minute = minute < 10 ? '0' + minute : minute;
      second = second < 10 ? '0' + second : second;
      switch (typeReturn) {
        case 'hour':
          return hour;
        case 'minute':
          return minute;
        case 'second':
          return second;
        default:
          return hour + ':' + minute + ':' + second;
      }
    },
    minusTwoDate(dateStart, dateEnd) {
      const date1 = moment(dateEnd, 'YYYY-MM-DD');
      const date2 = moment(dateStart, 'YYYY-MM-DD');
      const diffInSeconds = date2.diff(date1, 'days');
      return diffInSeconds;
    },
    minusTwoTime(timeEnd, timeStart,format='HH:mm:ss') {
      const time1 = moment(timeStart, format);
      const time2 = moment(timeEnd, format);
      const diffInSeconds = time2.diff(time1, 'seconds');
      return diffInSeconds;
    },
    formatTime(num) {
      return num.toString().padStart(2, '0');
    },
    formatCurrentTime(currentTime) {
      currentTime.setSeconds(currentTime.getSeconds() + 1);
      var hours = this.formatTime(currentTime.getHours());
      var minutes = this.formatTime(currentTime.getMinutes());
      var seconds = this.formatTime(currentTime.getSeconds());
      var timeResult = hours + ':' + minutes + ':' + seconds;
      return timeResult;
    },
    randomStr(length) {
      let result = ""
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      let counter = 0
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
      }
      return result
    },
    /******* #END | TRN********/
    showToast(variant,title,content,persistent=false) {
      let text = content
      let h    = this.$createElement
      let id   = `asb-toast-${title}`
      if(variant == 'success') {
        // text = h( 'font-awesome-icon',{ props: { icon: ['fas', 'circle-check'] }, class : ['mr-2 text-success'] } , )
        text = h( 'img',{ domProps : {src : successIconURL }, class : ['mr-2'] } , )
      }
      if(variant == 'danger') {
        // text = h( 'font-awesome-icon',{ props: { icon: ['fas', 'circle-xmark'] }, class : ['mr-2 text-danger'] } , )
        text = h( 'img',{ domProps : {src : errorIconURL }, class : ['mr-2'] } , )
      }
      if(variant == 'warning') {
        // text = h( 'font-awesome-icon',{ props: { icon: ['fas', 'circle-exclamation'] }, class : ['mr-2 text-warning'] } , )
        text = h( 'img',{ domProps : {src : warningIconURL }, class : ['mr-2'] } , )
      }
      if(variant == 'info') {
        // text = h( 'font-awesome-icon',{ props: { icon: ['fas', 'circle-exclamation'] }, class : ['mr-2 text-warning'] } , )
        text = h( 'img',{ domProps : {src : infoIconURL }, class : ['mr-2'] } , )
      }
      let $closeButton = h( 'button',{ on: { click: () => this.$bvToast.hide(id) }, class : ['close ml-2'] }, 'x')
      let $msg     = h('div',{ class :['d-inline-flex align-items-center']},[text, content])
      let $content = h('div', {class : ['d-flex justify-content-between align-items-center']}, [$msg, $closeButton])
      if(!persistent){
        $content = [text, content]
      }
      this.$bvToast.toast($content, {
        id : id,
        noAutoHide : persistent,
        // title   : title,
        // variant : variant,
        toaster       : 'b-toaster-bottom-center',
        solid         : true,
        noCloseButton : true,
        autoHideDelay : 2000
      })
    },
    genRandomHex(size) {
      return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')
    },
    getFileBase64(file) {
      return new Promise((resolve,reject)=>{
        // let file   = element.files[0];
        let reader = new FileReader();
        reader.onloadend = function() {
          // console.log('RESULT', reader.result)
          resolve(reader.result)
        }
        reader.readAsDataURL(file);
      })
    },
    dateToYMD(date){
      let d = new Date(date)
      return d.toISOString().split('T')[0]
    },
    isValidURL(str) {
      let regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      return regex.test(str)
    },
    //this method will compress the images
    async compressImage(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
          reader.onload = async (event) => {
            //this will convert file into size
            const fileSize = Math.round(event.total / 1024);
            //2MB file
            const maxFileSize = 2048;
            //compress only those files which are more than 2MB
              if (fileSize > maxFileSize) {
                const image = new Image();
                image.src = event.target.result;
                image.onload = () => {
                  const image = new Image();
                  image.src = event.target.result;
                  image.onload = () => {
                    //draw image on canvas and set width and height
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxWidth = 800;
                    const maxHeight = 600;
                    //file size to set
                    const maxFileSize = 2 * 1024 * 1024;
                    let width = image.width;
                    let height = image.height;
                    //set width if greater than height
                      if (width > height) {
                        if (width > maxWidth) {
                          height *= maxWidth / width;
                          width = maxWidth;
                        }
                      } else {
                          //vice versa
                        if (height > maxHeight) {
                          width *= maxHeight / height;
                          height = maxHeight;
                        }
                      }
                      //again set images
                      canvas.width = width;
                      canvas.height = height;
                      ctx.drawImage(image, 0, 0, width, height);
                      const compressedBase64 = canvas.toDataURL('image/jpeg', 0.8);
                      if (compressedBase64.length > maxFileSize) {
                        console.warn('Compressed image size exceeds 2MB. Adjust parameters.');
                        return;
                      }
                      resolve(compressedBase64);
                  }
                }
              } else {
                  resolve(event.target.result);
              }
          };
          reader.readAsDataURL(file);
      });
    },
    //convert the array to binary
    arrayBufferToBase64(buffer) {
      const binary = Array.from(new Uint8Array(buffer)).map(byte => String.fromCharCode(byte)).join('');
      return btoa(binary);
    },
    //this method will compress the pdf file
    async compressPdf(file, compressionLevel = 9) {
      return new Promise(async (resolve) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            //this will convert file to arrays
            const pdfData = new Uint8Array(event.target.result);
            //this will load array form data
            const pdfDoc = await PDFDocument.load(pdfData);
            //this will compress to level which you pass
            const modifiedPdfBytes = await pdfDoc.save({compressionLevel});
            const compressedBase64 = this.arrayBufferToBase64(modifiedPdfBytes);
            //this will use to format the array
            const finalBase64 = `data:application/pdf;base64,${compressedBase64}`;
            resolve(finalBase64);
          } catch (error) {
            console.error('Error compressing PDF:', error);
            resolve(null);
          }
        };
        reader.onerror = (event) => {
          console.error('FileReader error:', event);
          resolve(null);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    convertPhpFormatToJs(serverFormat){
      let jsFormat = '';
      const formatMapping = {'d':'DD','D':'ddd','j':'D','l':'dddd','N':'','S':'','w':'','z':'','W':'','F':'MMMM','m':'MM','M':'MMM','n':'M','t':'','L':'','o':'','Y':'YYYY','y':'YY','a':'a','A':'A','B':'','g':'h','G':'H','h':'hh','H':'HH','i':'mm','s':'ss','u':'','e':'','I':'','O':'','P':'','T':'','Z':'','c':'','r':'','U':''};
      for (let i = 0; i < serverFormat.length; i++) {
          const char = serverFormat[i];
          if (formatMapping[char]) {
              jsFormat += formatMapping[char];
          } else {
              jsFormat += char;
          }
      }
      return jsFormat;
    }
  },
  filters:{
    /**
    * @Description
    *
    * 1. This filter is used to put the commas after 3 digits
    **/
    commas(val) {
      if(val === null || val === undefined) {
        return
      }
      if (val === 0) {
        return '000'
      }
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    /**
    * @Description
    *
    * 1. This filter is used to captalize the first letter of word
    **/
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    /**
    * @Description
    *
    * 1. This filter is used to put the leading 3 zeros for a number
    **/
    padZeros(num){
      const zeroPad = (num, places) => String(num).padStart(places, '0')
      return zeroPad(num, 3)
    },
    /**
    * @Description
    *
    * 1. This filter is used to parse the string in human readable, means
    * 2. It removed the under scores from string and catalize the first letter of each word
    *
    * @param str { String }
    * @return String
    **/
    humanize(str){
      if(!str) {return}
      let i, frags = str.split('_')
      for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
      }
      return frags.join(' ')
    },
    /**
    * This filter simple converts the UNIX time string to local date format e.g (12/03/2023)
    **/
    formatUnixTime(unixStr){
      return new Date(unixStr * 1000).toLocaleDateString().split('T')[0]
    },
    humanizeUnixTime(unixStr) {
      return moment.unix(unixStr).tz(store.getters.TIMEZONE).format('hh:mm:ss A');
    },
    humanizeUnixDateTime(unixStr) {
      return moment.unix(unixStr).tz(store.getters.TIMEZONE).format('MM/DD/YYYY hh:mm A');
    },
  }
}