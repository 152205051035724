export default {
  state: () => ({ 
    EMPLOYEE_LOGGED_IN : false,
    EMPLOYEE_DATA: [],
    COMPANY_TIMEZONE: 'America/Denver',
  }),
  mutations: {
    SET_EMPLOYEE_DATA(state, data){
      state.EMPLOYEE_DATA = data
    },
    SET_LOGIN_STATE(state, loginState){
      state.EMPLOYEE_LOGGED_IN = loginState
    },
    UPDATE_EMPLOYEE_DATA(state, data){
      let employee = state.EMPLOYEE_DATA
      
      employee[data.key]  = data.value
      state.EMPLOYEE_DATA = employee
    },
    UPDATE_BULK_EMPLOYEE_DATA(state, data){
      let employee = state.EMPLOYEE_DATA
      let updatedEmployee = { ...employee, ...data }
      state.EMPLOYEE_DATA = updatedEmployee
    },
    UPDATE_JWT_TOKEN(state,token){
      state.EMPLOYEE_DATA.token = token
    },
    SET_COMPANY_TIMEZONE(state, data){
      state.COMPANY_TIMEZONE = data
    },
  },
  getters: {
    JWT_TOKEN(state){
      return state.EMPLOYEE_DATA ? state.EMPLOYEE_DATA.token : undefined
    },
    EMPLOYEE_UUID(state){
      return state.EMPLOYEE_DATA ? state.EMPLOYEE_DATA.uuid : undefined
    },
    EMPLOYEE_DATA(state){
      return state.EMPLOYEE_DATA ? state.EMPLOYEE_DATA : undefined
    },

    /***TRN***/
    TIMEZONE(state) {
      let timezone = state.COMPANY_TIMEZONE
      
      if(state.EMPLOYEE_LOGGED_IN && state.EMPLOYEE_DATA && state.EMPLOYEE_DATA.timezone) 
      {
        timezone = state.EMPLOYEE_DATA.timezone
        if(timezone == 'company_timezone') {
          timezone = state.EMPLOYEE_DATA.company.timezone ? state.EMPLOYEE_DATA.company.timezone : 'America/Denver'
        }
      } 

      return timezone
    },
    TIME_FORMAT_DEFAULT(state){
      return state.EMPLOYEE_DATA.admin_time_format && state.EMPLOYEE_DATA.admin_time_format == 'Military' ? 1 : 0;
    }
    /**** #END | TRN ****/

  },
  actions: {
    
  }
}