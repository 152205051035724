<template>
  <div class="border top-second-header" :class="className">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TopSecondHeader',
  props: {
    className: '',
  },
  data: () => ({}),
  mounted() {},
  methods: {},
}
</script>