import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import { App, URLOpenListenerEvent } from '@capacitor/app'

Vue.use(VueRouter)


const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackPrefetch: true, */ /* webpackChunkName: "home" */'../views/HomeView.vue'),
    meta : {
      requiresAuth : true,
      metaTitle    : 'ASB Employee | Home'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
     meta : {
      requiresAuth : false,
      metaTitle    : 'ASB Employee | Login'
    },
    beforeEnter(to, from, next){
      if(store.state.auth.EMPLOYEE_LOGGED_IN){
        next({path : '/'})
      }
      else
      {
        next()
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue'),
    meta : {
      requiresAuth : false,
      metaTitle    : 'ASB Employee | Forgot Password'
    }
  },
  {
    path: '/reset-password/:workerId/:token',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
    meta : {
      requiresAuth : false,
      metaTitle    : 'ASB Employee | Reset Password'
    }
  },
  {
    path: '/signup/:token',
    name: 'WorkerSignup',
    component: () => import('../views/WorkerSignup.vue'),
    meta : {
      requiresAuth : false,
      metaTitle    : 'ASB Employee | Signup'
    }
  },
  {
    path: '/create-account/:token',
    name: 'CreateAccount',
    component: () => import('../views/CreateAccount.vue'),
    meta : {
      requiresAuth : false,
      metaTitle    : 'ASB Employee | Create Account'
    }
  },
  {
    path: '/display-board',
    name: 'DisplayBoard',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "DisplayBoardView" */ '../views/DisplayBoardView.vue'),
     meta : {
      requiresAuth : true,
      metaTitle    : 'ASB Employee | Display Board'
    }
  },
  {
    path: '/missing-punch',
    name: 'MissingPunch',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "PucnhHistoryView" */ '../views/MissingPunchView.vue'),
    meta: {
      requiresAuth: true,
      pageTitle : 'Missing Punch',
      metaTitle : 'ASB Employee | Missing Punch'
    }
  },
  {
    path: '/schedules',
    name: 'Schedules',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "SchedulesView" */ '../views/SchedulesView.vue'),
     meta : {
      requiresAuth : true,
      pageTitle    : 'Schedule',
      metaTitle    : 'ASB Employee | Schedule'
    }
  },
  {
    path: '/schedules/:date',
    name: 'SchedulesDetail',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "SchedulesView" */ '../views/SchedulesView.vue'),
     meta : {
      requiresAuth : true,
      pageTitle    : 'Schedule',
      metaTitle    : 'ASB Employee | Schedule'
    }
  },
  {
    path: '/measurements',
    name: 'measurements',
    component: () => import('../views/MeasurementsView.vue'),
     meta : {
      requiresAuth : true,
      pageTitle    : 'Measurements',
      metaTitle    : 'ASB Employee | Measurements'
    }
  },
  {
    path: '/work-orders',
    name: 'WorkOrders',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "WorkOrdersView" */ '../views/WorkOrdersView.vue'),
    meta : {
      requiresAuth : true,
      pageTitle    : 'Work Orders',
      metaTitle    : 'ASB Employee | Work Orders'
    }
  },
  {
    path: '/work-orders/:workOrderId',
    name: 'WorkOrderDetail',
    component: () => import('../views/WorkOrderDetailView.vue'),
    meta : {
      requiresAuth : true,
      pageTitle    : 'Work Orders',
      metaTitle    : 'ASB Employee | Work Orders'
    }
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "AlertsView" */  '../views/AlertsView.vue'),
     meta : {
      requiresAuth : true,
      pageTitle    : 'Alerts & Notifications',
      metaTitle    : 'ASB Employee | Alerts & Notifications'
    }
  },
  // {
  //   path: '/checklist',
  //   name: 'Checklist',
  //   component: () => import('../views/ChecklistView.vue'),
  //   meta : {
  //     requiresAuth : true
  //   }
  // },
  {
    path: '/equipments',
    name: 'Equipments',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "EquipmentsView" */ '../views/EquipmentsView.vue'),
    meta : {
      requiresAuth : true,
      pageTitle    : 'Equipment',
      metaTitle    : 'ASB Employee | Equipment'
    }
  },
  {
    path: '/equipments/:equipmentId',
    name: 'EquipmentDetail',
    component: () => import('../views/EquipmentDetailView.vue'),
    meta : {
      pageTitle    : 'Equipment',
      metaTitle    : 'ASB Employee | Equipment'
    }
  },
  {
    path: '/scanned-equipment',
    name: 'EquipmentPublic',
    component: () => import('../views/ScannedEquipmentView.vue'),
    meta : {
      pageTitle    : 'Equipment',
      metaTitle    : 'ASB Employee | Equipment'
    }
  },
  {
    path: '/public/equipment/:uuid',
    name: 'EquipmentQRCodeURLv2',
    component: () => import('../views/ScannedEquipmentView.vue'),
    meta : {
      pageTitle    : 'Equipment',
      metaTitle    : 'ASB Employee | Equipment'
    }
  },
  //TEMP ROUTE : To handle old QR images to redirect to new v2
  {
    path: '/mobile/equipment/unit',
    name: 'EquipmentQRCodeURLv1',
    component: () => import('../views/ScannedEquipmentView.vue'),
    meta : {
      pageTitle    : 'Equipment',
      metaTitle    : 'ASB Employee | Equipment'
    }
  },
  {
    path: '/punch-history',
    name: 'PunchHistory',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "PunchHistoryView" */ '../views/PunchHistoryView.vue'),
    meta : {
      requiresAuth : true,
      pageTitle    : 'Punch History',
      metaTitle    : 'ASB Employee | Punch History'
    }
  },
  {
    path: '/safety',
    name: 'Safety',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "SafetyView" */ '../views/SafetyView.vue'),
    meta : {
      requiresAuth : true,
      pageTitle    : 'Safety and Training',
      metaTitle    : 'ASB Employee | Safety and Training'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "SettingsView" */ '../views/SettingsView.vue'),
    meta : {
      requiresAuth : true,
      pageTitle    : 'Settings',
      metaTitle    : 'ASB Employee | Settings'
    }
  },
  { path: "*", component: () => import('../views/PageNotFound.vue') },
  //TEMP ROUTE
  {
    path :'/mobile',
    redirect : '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})


router.beforeEach((to, from, next) => {

  /*Create Page dynamic Title*/
  const title = to.meta.metaTitle
  // If the route has a pageTitle, set it as the page title of the document/page
  if (title) {
    document.title = title
  }

  /*Validate the LOGGED IN STATE*/
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.EMPLOYEE_LOGGED_IN) {
      router.push({ path: 'login' }) // go to Login Page
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()! //Login Page
  }
})


router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})



/*NATIVE APP LINKINKG WITH EXTERNAL ROUTES*/
App.addListener('appUrlOpen', data => {

  const SLUG = data.url.toString().replace(/^.*\/\/[^\/]+/, '')

  // We only push to the route if there is a slug present
  if(SLUG) {
    router.push(SLUG)
  }

})


export default router
