import { PushNotifications } from '@capacitor/push-notifications'
import { Device } from '@capacitor/device'
import store from '@/store/index.js'

import {CapacitorSilentNotifications} from 'capacitor-plugin-silent-notifications'



/**
* This binds the event listener to receive token, receive push notification events from firebase
* In the registration Listener upon success it calls the saveTokenOnBackend to save the token on backend
* 
**/
const addListeners = async () => {

  await PushNotifications.removeAllListeners()

  await PushNotifications.addListener('registration', token => {
    //console.info('Registration token: ', token.value)
    //save token on backend
    if(!store.getters.FCM_TOKEN_REGISTERD){
      saveTokenOnBackend(token.value)
    }
  })

  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error)
  })

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    //console.log('Push notification received: ', JSON.stringify(notification))

    //IF JUST BACKGRDOUND MESSAGE RECEIVEDING FROM FIREBASE (i.e emit for refrehsing page)
    if(notification.data !== undefined) {

      // alert(notification.data.title)
      // alert(notification.data.body)

      Vue.$root.$emit(notification.data.title, notification.data.body)
    }
  })

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    //console.log('Push notification action performed:', JSON.stringify(notification),  notification.actionId, notification.inputValue)
    if (notification.actionId === 'tap') {
      Vue.$router.push('/alerts')
    }
  })





  ///////////////////////////////////////////////////////////////////////////////////////////////////
  if(Capacitor.getPlatform() === 'ios'){

    //console.log(CapacitorSilentNotifications)

    CapacitorSilentNotifications.addListener('silentNotificationReceived', async (payload) => {
      //console.log('silentNotificationReceived',payload)

      Vue.$root.$emit(payload.title, payload.body)
    })
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////



}

/**
* This method is used to get notification permission of devices
* If permission status is not granted then request the permissions 
* When permissions are assigned then registers the push notification event (Firebase actually)
* 
**/
const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions()

  if(permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions()
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!')
  }

  await PushNotifications.register()
}

/**
* This method is used to receive the Push Notification on Native mobile devices (capcacitor plugin)
* 
**/
const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications()
  //console.log('delivered notifications', notificationList)
}


/**
* This method is used to get the Device Unique ID in order to save FCM token on backend
* It returns unique UUID and returns if Device is same (to keep multiple tokens of a user/worker against each device)
**/
const getDeviceId = async () => {
  const info = await Device.getId()
  return info?.identifier
}

/**
* This method is used to store the FCM token on backend
* In payload it sets the logged-in worker UUID , token and device ID
* If success response received then updates the state in localStorage to avoid redundant calls
*
* @param token { UUID String }
* @return void
**/
const saveTokenOnBackend = async(token) => {
  let payload = {
    'worker_uuid' : store.getters.EMPLOYEE_UUID,
    'fcm_token'   : token,
    'device_id'   : await getDeviceId()
  }

  store.dispatch('HTTP_POST_REQUEST', { url: `employee/fcm-token/store`, payload: payload }).then(res=>{
    if(res.data.status == 'success'){
      store.commit('SET_FCM_TOKEN_REGISTERD', true)
    }
  })
}

/**
* This method is first method which is being called in App.vue and init the Native Mobile Devices Permissions
* It first takes the notification permissions and then add listener to get Token from Firebase
* Upon the success response of token called the saveTokenOnBackend method to store token on backend
* 
**/
const __initNativePushNofication__  = async() => {
  await registerNotifications()
  await addListeners()
  await getDeliveredNotifications()
}


export default __initNativePushNofication__
