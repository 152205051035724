import { initializeApp } from 'firebase/app'
import { getMessaging , onMessage, getToken} from 'firebase/messaging'

import { Device } from '@capacitor/device'
import store from '@/store/index.js'


const V_API_D_KEY    = 'BNOQYgVbwp6NaODalW3xWW_JX5alyUWpf1KIFNPd07N7ywQJ3KZxWhGQfgruaI6nrNX9gOg1V2HVR3aSHinCEUI'
const firebaseConfig = {
  apiKey            : 'AIzaSyCqStPrN6OP92OnCLeEP-xE3Li28zDq74Q',
  authDomain        : 'tasktracker-d0c58.firebaseapp.com',
  projectId         : 'tasktracker-d0c58',
  storageBucket     : 'tasktracker-d0c58.appspot.com',
  messagingSenderId : '73744427152',
  appId             : '1:73744427152:web:b103aa42174f3c9fa11015',
  measurementId     : 'G-E38B8QVER8'
}


// Initialize Firebase FCM to get a reference to the service
const app       = initializeApp(firebaseConfig)
const messaging = getMessaging()


/**
* This is FCM ForeGround message Listener to receive the notification when window is Focused
* When notification is received, it shows the Browser Notification using JS Notification API
* The payload of notification has title, body and all other option
**/
onMessage(messaging, (payload) => {
  // console.log('Foreground Message received. ', payload)

  //IF PUSH NOTIFICATION
  if(payload.notification !== undefined) {
    const title = payload.notification.title
    const body  = payload.notification.body

    const notification = new Notification(title, {
      body  : body,
      tag   : title,
      sound : 'default',
      icon  : '/img/icons/android-chrome-192x192.png'
    })
  }

  //IF JUST BACKGRDOUND MESSAGE RECEIVEDING FROM FIREBASE (i.e emit for refrehsing page)
  if(payload.data !== undefined) {
    Vue.$root.$emit(payload.data.title, payload.data.body)
  }

})


/**
* This method is used to get the Device Unique ID in order to save FCM token on backend
* It returns unique UUID and returns if Device is same (to keep multiple tokens of a user/worker against each device)
**/
const getDeviceId = async () => {
  const info = await Device.getId()
  return info?.identifier
}


/**
* This method is used to store the FCM token on backend
* In payload it sets the logged-in worker UUID , token and device ID
* If success response received then updates the state in localStorage to avoid redundant calls
*
* @param token { UUID String }
* @return void
**/
const saveTokenOnBackend = async(token) => {
  let payload = {
    'worker_uuid' : store.getters.EMPLOYEE_UUID,
    'fcm_token'   : token,
    'device_id'   : await getDeviceId()
  }

  store.dispatch('HTTP_POST_REQUEST', { url: `employee/fcm-token/store`, payload: payload }).then(res=>{
    if(res.data.status == 'success'){
      store.commit('SET_FCM_TOKEN_REGISTERD', true)
    }
  })
}


/**
* This method is first method which is being called in App.vue 
* It first takes the notification permissions and then add listener to get Token from Firebase
* Upon the success response of token called the saveTokenOnBackend method to store token on backend
* 
**/
const __initWebPushNofication__  = async() => {

  Notification.requestPermission().then((permission) => {
    if(permission === 'granted') {

      getToken(messaging, { vapidKey: V_API_D_KEY }).then((currentToken) => {

        // console.log(currentToken)

        if(currentToken && !store.getters.FCM_TOKEN_REGISTERD) {
          saveTokenOnBackend(currentToken)
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
      })

    }
  })

}


export default __initWebPushNofication__
