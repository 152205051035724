import axios from '@/plugins/axios'

export default {
  state: () => ({ 

  }),
  mutations: {

  },
  getters: {
    
  },
  actions: {
    HTTP_POST_REQUEST({commit,state},data)
    {
      return axios
      .post(
        data.url,
        data.payload
      )
      .then(response => {
        return response
      })
    },
    HTTP_PUT_REQUEST({commit,state},data)
    {
      return axios
      .put(
        data.url,
        data.payload
      )
      .then(response => {
        return response
      })
    },
    HTTP_GET_REQUEST({commit,state},data)
    {
      return axios
        .get(data.url,{ data:{}, params : data.payload })
        .then(response => { return response })
    },
    HTTP_DELETE_REQUEST({commit,state},data)
    {
      return axios
        .delete(data.url, { data:{}, params : data.payload })
        .then(response => { return response })
    }
  }
}