export default {
  state: () => ({
    WORK_ORDERS_COUNT       : 0,
    PART_LIST_COUNT         : 0,
    ALERT_WORKER_COUNT      : 0,
    FCM_TOKEN_REGISTERD     : false,
    TRANSLATE_TASK          : null,
    CAMERA_CHOICE           : null,
    HS_HIDE_BANNER          : null,
    WORKER_NAME_UPDATE_HOUR : null,
    TRANSLATIONS            : []
  }),
  mutations: {
    SET_WORK_ORDERS_COUNT(state, data) {
      state.WORK_ORDERS_COUNT = data
    },
    SET_PART_LIST_COUNT(state, data) {
      state.PART_LIST_COUNT = data
    },
    SET_ALERT_WORKER_COUNT(state, data) {
      state.ALERT_WORKER_COUNT = data
    },
    SET_FCM_TOKEN_REGISTERD(state, data) {
      state.FCM_TOKEN_REGISTERD = data
    },
    SET_TRANSLATE_TASK(state, data) {
      state.TRANSLATE_TASK = data
    },
    SET_CAMERA_CHOICE(state, data) {
      state.CAMERA_CHOICE = data
    },
    UPDATE_HIDE_BANNER(state,data){
      state.HS_HIDE_BANNER = data
    },
    SET_WORKER_NAME_UPDATE_HOUR(state,data){
      state.WORKER_NAME_UPDATE_HOUR = data
    },
    SET_TRANSLATIONS(state,data){
      state.TRANSLATIONS = data
    },
  },
  getters: {
    WORK_ORDERS_COUNT(state) {
      return state.WORK_ORDERS_COUNT
    },
    PART_LIST_COUNT(state) {
      return state.PART_LIST_COUNT
    },
    ALERT_WORKER_COUNT(state) {
      return state.ALERT_WORKER_COUNT
    },
    FCM_TOKEN_REGISTERD(state) {
      return state.FCM_TOKEN_REGISTERD
    },
    TRANSLATE_TASK(state) {
      return state.TRANSLATE_TASK
    },
    CAMERA_CHOICE(state){
      return state.CAMERA_CHOICE
    },
    HS_HIDE_BANNER(state){
      return state.HS_HIDE_BANNER;
    },
    WORKER_NAME_UPDATE_HOUR(state){
      return state.WORKER_NAME_UPDATE_HOUR;
    },
    TRANSLATIONS(state){
      return state.TRANSLATIONS;
    },

  },
  actions: {},
}
