<template>
  <div id="app">
    <native-app-banner v-if="!isNativePlatform"></native-app-banner>
    <!-- 404 -->
    <router-view v-if="!$route.name" />

    <!-- If LOGGED-IN -->
    <base-layout v-else-if="isLoggedIn || (!isLoggedIn && $route.name=='EquipmentDetail')">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
    </base-layout>

    <!-- If NOT LOGGED-IN -->
    <auth-layout v-else-if="!isLoggedIn">
      <router-view :key="$route.path"></router-view>
    </auth-layout>

  </div>
</template>

<script>


import __initNativePushNofication__ from '@/notifications/NativePushNotification.js'
import __initWebPushNofication__ from '@/notifications/WebPushNotification.js'


export default {
  components: {
    'auth-layout': () => import('@/layouts/AuthLayout'),
    'base-layout': () => import('@/layouts/BaseLayout'),
    'native-app-banner': () => import('@/components/shared/NativeAppBanner')
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.EMPLOYEE_LOGGED_IN
    },
    isNativePlatform(){
      return Capacitor.isNativePlatform()
    }
  },
  watch: {
    isLoggedIn: {
      handler(val) {
        if (val == true) {
          this.bindPushNotifications()
        }
      },
      immediate: true
    },
    '$store.getters.TRANSLATIONS'() {
      let lang = this.$store.getters?.EMPLOYEE_DATA?.language?.code?.toLowerCase() ?? 'en'
      let translations = this.$store.getters.TRANSLATIONS
      if(translations[lang]){
        this.$i18n.setLocaleMessage(lang, translations[lang])
        this.$i18n.locale = lang
      }
    },
  },
  data: () => ({}),
  async mounted() {
    this.setDynamicHeight()
    // this.bindPushNotifications()
  },
  methods: {
    setDynamicHeight() {
      let vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      // We listen to the resize event
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    },

    async bindPushNotifications() {
      if (this.isLoggedIn) {

        if (Capacitor.isNativePlatform()) {
          __initNativePushNofication__()
        }
        else {
          __initWebPushNofication__()
        }
      }
    },

  }
}
</script>