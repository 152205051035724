import Vue from 'vue'
import VueApp from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VeeValidate,{ Validator } from 'vee-validate'
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors',
})


/* import the fontawesome */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import '@/assets/scss/style.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)

import GeneralMixin from '@/mixins/GeneralMixin'
Vue.mixin(GeneralMixin)


import TopSecondHeader from '@/components/shared/TopSecondHeader'
Vue.component('top-second-header', TopSecondHeader)

import ToastConfirm from '@/components/shared/ToastConfirm'
Vue.component('toast-confirm', ToastConfirm)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('v-select', vSelect)

import Vue2Editor from 'vue2-editor'
Vue.use(Vue2Editor)


import { i18n }  from './plugins/i18n.js'


/*Register firebase service worker to receive background notifications*/
import { register } from 'register-service-worker'
register(process.env.VUE_APP_BASE_URL + 'firebase-messaging-sw.js')

import draggable from 'vuedraggable'
Vue.component('draggable',draggable)

//IF JUST BACKGRDOUND MESSAGE RECEIVEDING FROM FIREBASE (i.e emit for refrehsing page)
if(!Capacitor.isNativePlatform()){
  navigator.serviceWorker.addEventListener('message',(event)=> {
    if(event.data.data !== undefined) {
      window.Vue.$root.$emit(event.data.data.title, event.data.data.body)
    }
  })
}


import setAndroidStatusBarBGcolor from '@/native/android-status-bar.js'
setAndroidStatusBarBGcolor()


//To fix : if Native phone screen is locked, we lost the clockIN counter, cause app is going in active state
import { App } from '@capacitor/app'

App.addListener('appStateChange', ({ isActive }) => {
  if(isActive){
    window.Vue.$root.$emit('top-pull-refresh','Home')
  }
})
//////


Vue.config.productionTip = false
window.Vue = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(VueApp),
}).$mount('#app')
