import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import http from './modules/http.js';
import auth from './modules/auth.js';
import general from './modules/general.js';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    http,
    auth,
    general,
  },
  plugins: [vuexLocal.plugin],
});
