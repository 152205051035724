import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'

Vue.use(VueI18n)

const messages = Object.assign({},store.getters?.TRANSLATIONS) ?? { 'en': {} }

export const i18n = new VueI18n({
  locale         : store.getters?.EMPLOYEE_DATA?.language?.code?.toLowerCase() ?? 'en', // set locale
  fallbackLocale : 'en',
  messages       : messages
})


export function setI18nLanguage(lang) {
  i18n.locale = lang
  return lang
}

export function setI18nMessages (lang, messages){
  i18n.setLocaleMessage(lang, messages)
}