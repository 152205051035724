import { Device } from '@capacitor/device'
import store from '@/store'


/**
* @Description
* 
* 1. This module is common and shared for logging out user from one point
* 2. If first gets the device id and worker UUID and dispatch a request to backend to clear login period accociated temp data
* 3. It clears the local storage and refresh the page to login view
* 
* @params none
* @return void
**/
const logout = async() => {

  const info = await Device.getId()

  let payload = {
    worker_id : store.getters.EMPLOYEE_UUID,
    device_id : info?.identifier
  }

  store.dispatch('HTTP_POST_REQUEST', { url: `/employee/logout`, payload: payload }).then((response) => {
    if(response && response.data && response.data.status === 'success') {
      //we don't need anything here yet. Handle success logout response if needed
    }
  })
  .catch((err) => {
    console.log(err)
  })

  //Since we don't need to wait for the backend response, that's why it's outside then block
  localStorage.clear()
  window.location.href= '/'

}

export default logout