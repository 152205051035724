import { StatusBar, Style } from '@capacitor/status-bar'

/**
* To set android status bar background color 
**/
const setAndroidStatusBarBGcolor  = async() => {
  if(Capacitor.getPlatform() === 'android'){
    await StatusBar.setBackgroundColor({ color: '#074B41' })
  }
}

export default setAndroidStatusBarBGcolor